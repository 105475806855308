import {css, html, LitElement} from 'lit';
import '@vaadin/button';
import '@vaadin/horizontal-layout';
import '@vaadin/vertical-layout';
import '@vaadin/password-field';
import '@vaadin/text-field';
import '@vaadin/form-layout';
import '@vaadin/item';
import '@vaadin/list-box';
import '@vaadin/select';

import logo from './assets/dat-logo-rd.svg';

const authUrl = "https://www.dat.de/AuthorizationManager/service--/endpoint/tokenService";

export class LoginPage extends LitElement {

    static get properties() {
        return {
            values: { type: Object },
            loggedIn: { type: Boolean },
            username: { type: String },
            customerId: { type: Number },
            password: { type: String },
            error: { type: Boolean },
            i18n: { type: Object },
            env: { type: Array },
            server: { type: String },
            validate: false
        }
    }

    constructor() {
        super();

        this.loggedIn = false;
        this.error = false;

        this.env = [
            {
                label: "GOLD",
                value: "https://gold.dat.de"
            }, {
                label: "PROD",
                value: "https://www.dat.de"
            }];

    }

    firstUpdated(_changedProperties) {
        super.firstUpdated(_changedProperties);

        let locale = navigator.language.substring(0, 2) || "en";

        const translates = {
            "pl": {
                username: "Nazwa użytkownika",
                customerId: "Numer klienta",
                password: "Hasło",
                server: "Środowisko",
                login: "Login",
                error: "Błąd! Niewłaściwe dane logowania!"

            },
            "en": {
                username: "Username",
                customerId: "Customer ID",
                password: "Password",
                server: "Server",
                login: "Login",
                error: "Error! Invalid login data!!"
            }
        }

        this.i18n = translates[locale];

    }

    render() {
        return html`
            <div class="form-container shadow-xs">

                <div class="logo-container">
                    <div class="logo">
                        <img src="${logo}" alt="Logo DAT" style="max-width: 5em">
                    </div>
                </div>

                <vaadin-vertical-layout>

                    <vaadin-form-layout .responsiveSteps="${this.responsiveSteps}">
                        <vaadin-text-field label="${this.i18n?.customerId}"
                                           ?autofocus="true"
                                           required
                                           .value="${this.customerId}"
                                           pattern="\\d{7}"
                                           @value-changed="${(e) => this.customerId = e.detail.value}">
                        </vaadin-text-field>

                        <vaadin-text-field label="${this.i18n?.username}"
                                           required
                                           .value="${this.username}"
                                           @value-changed="${(e) => this.username = e.detail.value}">
                        </vaadin-text-field>

                        <vaadin-password-field label="${this.i18n?.password}"
                                               required
                                               .value="${this.password}"
                                               @value-changed="${(e) => this.password = e.detail.value}">
                        </vaadin-password-field>
                        
                        <vaadin-select
                                label="${this.i18n?.server}"
                                .items="${this.env}"
                                @value-changed="${(e) => this.server = e.detail.value}"
                                .value="${this.env[0].value}"
                        ></vaadin-select>

                    </vaadin-form-layout>

                    <vaadin-horizontal-layout theme="spacing" class="form-action">

                        <vaadin-button theme="primary" @click="${() => this.validateForm() && this.doLogin()}">
                            ${this.i18n?.login}
                        </vaadin-button>

                    </vaadin-horizontal-layout>

                    <div class="error-box">
                        <span class="error" ?hidden="${!this.error}">${this.i18n?.error}</span>
                    </div>

                </vaadin-vertical-layout>

                <div class="footnote">
                    DAT &copy; 2024 All rights reserved
                </div>

            </div>
        `;
    }

    validateForm() {

        let fields = this.shadowRoot.querySelectorAll("vaadin-form-layout > vaadin-text-field,  vaadin-form-layout > vaadin-password-field");

        fields.forEach(field => field.validate());

        return this.username?.length > 0 && this.customerId?.length > 0 && this.password?.length > 0;

    }

    showError() {
        this.error = true;
        setTimeout(() => this.error = false, 5000);
    }

    resetForm() {
        this.username = "";
        this.customerId = "";
        this.password = "";
        this.requestUpdate()
    }


    doLogin() {

        fetch(authUrl, {
            method: 'POST',
            mode: 'cors',
            body: JSON.stringify({
                action: "generateToken",
                customerNumber: this.customerId,
                user: this.username,
                password: this.password
            })
        })
            .then(data => {

                data.json()
                    .then(token => {

                            const url = `${this.server}/PartsInfo/eventList/eventList.html?productVariant=partsInfo&fabrikat=-&token=${token}`;

                            fetch(url).then(t => {

                                if (t.url.indexOf("AuthorizationManager") > 0) {
                                    this.showError();
                                } else {
                                    window.open(url, '_blank');
                                    this.resetForm();
                                    close();
                                }
                            });
                        }
                    );
            })


    }


    static get styles() {
        return css`
        :host {
            margin: 0 auto;
            padding: 2rem;
            text-align: center;
        }
        
        #iframeContainer {
            width: 100%;
            height: 100%;
        }
        
        .form-container {
            border-radius: var(--lumo-border-radius-m);
            border-color: var(--lumo-contrast-70pct);
            padding: var(--lumo-space-l);
            background-color: white;
            max-width: 360px;
        }
        
        .error-box {
            height: 1rem;
            margin-top: 1.5em;
        }
        
        .error {
            color: red;
        }
        
        .logo-container {
            display: flex;
            flex-direction: column;
            place-items: center;
            
        }
        
        .logo {
            display: block;
            width: 5rem;;

        }
        
        .form-action {
            margin-top: 2em;        
        }
        
        .footnote {
            font-size: 0.7rem;
            margin: 1em;

        }
        
        .shadow-xs {
            box-shadow: var(--lumo-box-shadow-xs);
        }
        `
    }
}

window.customElements.define('login-page', LoginPage);
